<template>
  <dx-util-popup
    ref="itemSelectorPopupRef"
    height="auto"
    width="50vw"
    :drag-enabled="false"
    :close-on-outside-click="true"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="itemSelectorPopupShown"
    @hidden="itemSelectorPopupHidden"
  >
    <div class="container-fluid px-0">
      <div>
        <h4 class="text-warning mb-2">
          Items Inside The Box
        </h4>
        <p v-if="isForSelection">
          Specify the quantity of products to be placed inside the boxes. If no product will be placed, set it to 0.
        </p>
        <div v-if="isForSelection">
          <div v-for="item in boxContent" :key="item.id" class="mb-3 mx-1 d-flex align-items-center">
            <div class="align-self-center">
              <img :src="item.smallImage || require('@/assets/images/undraw/no_images.svg')" style="width: 48px !important; margin-right: 0.625rem" class="rounded text-white">
            </div>
            <h5 class="flex-grow-1">
              {{ item.productName }}
            </h5>
            <div class="text-center mx-1">
              <dx-util-number-box v-model="item.quantity"
                :show-spin-buttons="true"
                label="Count" :width="100"
                :min="0"
                :max="item.orderQuantity-item.shippedQuantityInt"
                @value-changed="onQuantityChanged($event, item)"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <div v-for="item in box" :key="item.id" class="mb-3 mx-1 d-flex align-items-center">
            <div class="align-self-center">
              <img :src="item.smallImage || require('@/assets/images/undraw/no_images.svg')" style="width: 48px !important; margin-right: 0.625rem" class="rounded text-white">
            </div>
            <h5 class="flex-grow-1">
              {{ item.productName }}
            </h5>
            <div class="text-center mx-1 badge badge-primary" style="width: 42px; height:42px; padding: 10px;">
              <span style="font-size: 21px;" class="">
                {{ item.shipCount }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </dx-util-popup>
</template>

<script>
import useFbmState from '../useFbmStates'

export default {
  components: {
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    boxId: {
      type: String,
      default: '',
    },
    box: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const {
      currentOrder, getShippingLabels, orderItems, selectedOrderItems, orderItemsForSelection, boxes,
    } = useFbmState()
    return {
      currentOrder, getShippingLabels, orderItems, selectedOrderItems, orderItemsForSelection, boxes,
    }
  },
  data() {
    return {
      popupTitle: '',
    }
  },
  computed: {
    itemSelectorPopup() {
      return this.$refs.itemSelectorPopupRef.instance
    },
    boxContent() {
      return this.boxes?.filter(el => el.id === this.boxId)[0]?.shipBox || []
    },
    isForSelection() {
      return this.boxContent.length > 0
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.itemSelectorPopup.show()
        this.orderItems.forEach(item => {
          // eslint-disable-next-line no-param-reassign
          item.quantity = item.orderQuantity - item.shippedQuantityInt
        })
      },
    },
  },
  methods: {
    itemSelectorPopupShown() {
      this.popupTitle = 'Select the box items'
    },
    itemSelectorPopupHidden() {
    // Clear form when popup is closed
    },
    closePopup() {
      this.$emit('closed')
      this.itemSelectorPopup.hide()
    },
    onQuantityChanged(e, item) {
      const filteredBoxes = this.boxes ? this.boxes.filter(el => el.id === this.boxId) : []
      if (filteredBoxes.length > 0) {
        const filteredShipBox = filteredBoxes[0].shipBox.filter(el => el.id === item.id)
        if (filteredShipBox.length > 0) {
          filteredShipBox[0].updatedQuantity = e.value
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
